.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid orange;
    border-bottom: 3px solid orange;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.DayPicker {
    display: inline-block !important;
    font-size: 1rem !important;
}

.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    height: 600px;
    overflow-y: auto;
}
