.border-right {
    border-right: 1px solid #d7d7d7 !important;
}
.borders {
    border-left: 1px solid #d7d7d7 !important;
    border-right: 1px solid #d7d7d7 !important;
    margin: -1px;
}
.border-left {
    border-left: 1px solid #d7d7d7 !important;
}
.infoFlight{
    font-size: 11px;
}
.center{
    text-align: center;
}
.left{
    text-align: left;
    font-weight: bold;
}

.boarding{ 
    content: url(../images/plane-boarding-solid.svg);
    height: 20px; 
}
.boarding-flightBox{
    mask: url(../images/plane-boarding-solid.svg);
    display: inline-block;
    width: 46px;
    height: 43px;
    background: white;
    mask-size: cover;
}
.boarding-flightBox-black{
    mask: url(../images/plane-boarding-solid.svg);
    display: inline-block;
    width: 46px;
    height: 43px;
    background: black;
    mask-size: cover;
}
.boarding-flightBox-blue-small{
    mask: url(../images/plane-boarding-solid.svg);
    display: inline-block;
    width: 16px;
    height: 15px;
    background: rgb(60, 141, 188);
    mask-size: cover;
}
.boarding-w{ 
    content: url(../images/plane-boarding-solid-w.svg);
    height: 20px; 
}
.ascending{
    content: url(../images/plane-ascending-solid.svg);
    height: 16px;
    width: 17px;
}
.ascending-flightBox{
    mask: url(../images/plane-ascending-solid.svg);
    display: inline-block;
    width: 63px;
    height: 43px;
    background: white;
    mask-size: cover;
}
.ascending-flightBox-black{
    mask: url(../images/plane-ascending-solid.svg);
    display: inline-block;
    width: 63px;
    height: 43px;
    background: black;
    mask-size: cover;
}
.ascending-flightBox-blue-small{
    mask: url(../images/plane-ascending-solid.svg);
    display: inline-block;
    width: 20px;
    height: 15px;
    background: rgb(60, 141, 188);
    mask-size: cover;
}
.ascending-w{
    content: url(../images/plane-ascending-solid-w.svg);
    height: 16px;
    width: 17px;

}
.cruise{
    content: url(../images/plane-cruise-solid.svg);
    height: 16px;
    width: 17px;
}
.cruise-flightBox{
    mask: url(../images/plane-cruise-solid.svg);
    display: inline-block;
    width: 57px;
    height: 51px;
    background: white;
    mask-size: cover;
}
.cruise-flightBox-black{
    mask: url(../images/plane-cruise-solid.svg);
    display: inline-block;
    width: 57px;
    height: 51px;
    background: black;
    mask-size: cover;
}
.cruise-flightBox-blue-small{
    mask: url(../images/plane-cruise-solid.svg);
    display: inline-block;
    width: 17px;
    height: 15px;
    background: rgb(60, 141, 188);
    mask-size: cover;
}
.cruise-w{
    content: url(../images/plane-cruise-solid-w.svg);
    height: 16px;
    width: 17px;
}
.descending{
    content: url(../images/plane-descending-solid.svg);
    height: 16px;
    width: 20px;
}
.descending-flightBox{
    mask: url(../images/plane-descending-solid.svg);
    display: inline-block;
    width: 60px;
    height: 47px;
    background: white;
    mask-size: cover;
}
.descending-flightBox-black{
    mask: url(../images/plane-descending-solid.svg);
    display: inline-block;
    width: 60px;
    height: 47px;
    background: black;
    mask-size: cover;
}
.descending-flightBox-blue-small{
    mask: url(../images/plane-descending-solid.svg);
    display: inline-block;
    width: 20px;
    height: 17px;
    background: rgb(60, 141, 188);
    mask-size: cover;
}
.descending-w{
    content: url(../images/plane-descending-solid-w.svg);
    height: 16px;
    width: 20px;
}
.deplaning{
    content: url(../images/plane-deplanning-solid.svg);
    height: 20px; 
}
.deplaning-flightBox{
     mask: url(../images/plane-deplanning-solid.svg);
     display: inline-block;
     width: 46px;
     height: 43px;
     background: white;
     mask-size: cover;
 }
.deplaning-flightBox-black{
    mask: url(../images/plane-deplanning-solid.svg);
    display: inline-block;
    width: 46px;
    height: 43px;
    background: black;
    mask-size: cover;
}
.deplaning-flightBox-blue-small{
    mask: url(../images/plane-deplanning-solid.svg);
    display: inline-block;
    width: 16px;
    height: 15px;
    background: rgb(60, 141, 188);
    mask-size: cover;
}
.deplaning-w{
    content: url(../images/plane-deplanning-solid-w.svg);
    height: 20px; 
}
.landed{ 
    height: 20px;
    width: 20px;
}
.landed-flightBox{
    height: 20px;
    width: 20px;
}
.landed-flightBox-black{
    mask: url(../images/world-location.svg);
    display: inline-block;
    width: 50px;
    height: 50px;
    background: black;
    mask-size: cover;
}
.landed-flightBox-blue-small{
    mask: url(../images/world-location.svg);
    display: inline-block;
    width: 15px;
    height: 15px;
    background: rgb(60, 141, 188);
    mask-size: cover;
}
.landed-w{ 
    height: 20px;
    width: 20px;
}
.phaseicon{
    width: 25px !important;
}

.icon-plane{
    content: url(../images/plane.svg);
    height: 30px !important;
    width: 30px !important;
}
