.Th {
}

.SpanLeft {
    float:left
}

.SpanRight {
    float:right
}

.sorting:after {
    font-family: FontAwesome;
    opacity:0.2;
    content: "\f0dc";
    float:right;
}

.oderByDESC:after {
    font-family: FontAwesome;
    opacity:1;
    content: "\f0de";
    float:right;
}

.oderByASC:after {
    font-family: FontAwesome;
    opacity:1;
    content: "\f0dd";
    float:right;
}

th {
    cursor: pointer;
}
