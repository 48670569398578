.settings{
    text-align: center;
}

.box-settings-title{
    font-size: 14px !important;
}

th{
    text-align: center;
}

.clickable{
    cursor:pointer;
}