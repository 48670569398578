.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 2.7rem;
    line-height: 2.7rem;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-container > input {
    width: 100%;
    height: 40px;
    border: 1px solid #d2d6de;
    padding-left: 10px;
}

.react-datepicker, .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-size: 15px;
}