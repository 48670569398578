@import './app.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

//color
$color-success: #528116;
$color-success-secondary: #808534;
$color-warning: #F0AD4E;
$color-error: #C9302C;
$color-primary: #BF9976;
$color-notice: #FFE454;
$color-aqua: #5BC0DE;
$color-white: #fff;
$color-grey: #CED4D9;
$color-secondary: #6C757D;
$color-grey-b: #495157;
$color-menu-sidebar-bg: #343a40;
$color-black-text: #000;

//alert dashboard

.icon-alert {
    display: inline-block;
    padding: 2px 4px 1px;
    margin: 3px 6px 0 0;
}

.bg-green, .callout.callout-success {
    background-color: $color-success !important;
    border-color: $color-success !important;
    color: $color-white !important;
}

.callout.callout-info, .alert-info, .label-info, .modal-info {
    background-color: $color-aqua !important;
    border-color: $color-aqua !important;
    color: $color-black-text !important;
}

.bg-orange, .bg-yellow {
    background-color: $color-warning !important;
    color: $color-black-text !important;
}

.bg-red {
   background-color: $color-error !important;
   color: $color-white!important;
}

.bg-blue {     
    background-color: $color-aqua !important;
    color: $color-black-text !important;
}

.bg-gray {     
    background-color: $color-secondary !important; 
    color: $color-white !important;
}

.alert-notice, .bg-notice, .callout.callout-notice, .label-notice, .modal-notice .modal-body {
    background-color: $color-notice!important;
    color: $color-black-text !important;
}

//navbar 
.skin-blue .main-header .navbar {
    background-color: $color-white!important;
    color: $color-menu-sidebar-bg !important;
}

.skin-blue .main-header .logo {
    background-color: $color-white !important;
    color: $color-menu-sidebar-bg !important;
}

.skin-blue .main-header .navbar .nav>li>a {
    color: $color-menu-sidebar-bg !important;
}

.main-header .sidebar-toggle {
    background-color: $color-white !important;
    color: $color-menu-sidebar-bg !important;
    border-right: 1px solid $color-grey !important;
    border-left: 1px solid $color-grey !important;
    content: "\f0c9";
    
    &:hover {
        color: $color-grey !important;
    }
}

// box
.box.box-primary {
    border-top-color: $color-primary !important;
}

.box.box-solid.box-primary > .box-header {
    color: $color-black-text !important;
    background: $color-primary !important;
    background-color: $color-primary !important;
}

.box.box-solid.box-primary {
    border: 1px solid $color-primary !important;
}

.bg-gray > .info-box-content > .progress .progress-bar{
    background: $color-white !important;
}

//sidebar
.skin-blue .sidebar-menu>li.active>a {
    border-left-color: $color-white !important;
}

/* From display Sonata Bundle*/

body {
    font-family: 'Open sans';
}

.help-block{
    color: $color-aqua;
}
/*Bloc info*/
.bg-aqua {
    background-color: $color-aqua!important;
}

/*Link on homepage*/
a.btn.btn-link.btn-flat {
    color: $color-primary;
}

.form-control:focus {
    border: 1px solid $color-grey !important;
    box-shadow: 0px 0px 8px $color-primary!important;
}

.skin-blue .main-header .navbar .nav>li>a:active {
    color: $color-primary;
}

                    /******                 2 - Bar                 ******/

/* 2.1 left sidebar*/

.fa-search {
    color:  $color-primary;
}

.skin-blue .sidebar-form .btn{
    background: $color-white;
    /* Dark40 */
    border: 1px solid $color-grey;
    box-sizing: border-box;
    border-radius: 4px;
}

.skin-blue .sidebar-form input[type="text"] {
    background: $color-white;
}

.treeview-menu>li>a:hover, .skin-blue .treeview-menu>li>a:active {
    color:  $color-primary!important;
}

.skin-blue .sidebar-menu>li:hover>a, .skin-blue .sidebar-menu>li.active>a {
    color:  $color-primary!important;
}

.skin-blue .treeview-menu>li.active>a, .skin-blue .treeview-menu>li>a:hover {
    color:  $color-primary!important;
}

/* 2.2 Top navbar */

/*Hamburger*/
.skin-blue .main-header .navbar>.sidebar-toggle {
    color: $color-menu-sidebar-bg;
}

/*Brand text*/
body > div.wrapper > header > a > span {
    color: $color-primary;
}

/* label */
.sonata-filter-count {
    background-color: $color-grey-b;
}


/*first link in breadrum*/
body > div.wrapper > header > nav > div > div > ol > li:nth-child(1) > a > i {
    color: $color-primary;
}

                        /*****                           3 - button                     ******/

.btn-primary, .btn-success {
    background-color: $color-primary;
    border-color: $color-primary;
}

.btn-primary:hover, .btn-default:hover, .btn-success:hover, .btn-danger:hover, .btn-success:active {
    background-color: $color-secondary!important;
    color: $color-white;
    border-color: $color-secondary;
}

.btn-danger {
    background-color: $color-error;
    border-color:  $color-error;
}

.btn-default {
    background-color: $color-white;
    color: $color-primary;
}

/*action btn*/
td.sonata.ba-list-sfield.sonata-ba-list-actions {
    color: $color-primary!important;
}

/*action button*/
.sonata-ba-list-field-header-actions {
    color: $color-primary;
}

a.btn.btn-success {
    color: $color-white;
}

a.btn.btn-danger {
    color: $color-white;
}

/* Left button group */
.navbar-right > .navbar-btn {
    color: $color-primary;
    border-color: $color-primary;
}

/* ----- Custom oversize buttons  */
.main-menu {
    margin: 10px 0px;
}

.main-menu > ul {
    float: left !important;
    padding: 0;
    list-style: none;
}

.main-menu > ul > li {
    float: left;
    margin: 10px 5px;
}

.main-menu > .navbar-nav {
    margin: 0;
}

.main-menu > ul > li > a {
    padding: 6px 12px;
    color: $color-white;
    background-color: $color-primary;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    display: inline-block;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    display: inline;
}

.main-menu > ul > li > a:hover,
.main-menu > ul > li > a:active,
.main-menu > ul > li > a:focus {
    color: $color-white;
    display: inline;
    background-color: $color-secondary;
}

.main-menu > ul > .open > a,
.main-menu > ul > .open > a:focus{
    color: $color-white;
    display: inline;
    background-color:$color-secondary;
}

/*btn delete table*/
.select2-result-label {
    background-color:  $color-white!important;
    color: $color-secondary!important;
}

.select2-result-label:hover{
    background-color: $color-secondary!important;
    color: $color-white!important;
}

.select2-choice, .select2-choices, .select2-drop {
    border-color:  $color-primary!important;
}

                    /******             4 -  Modal, alert..                  ******/
.aircraft-name {
    color: back;
}

.label {
    font-size: 90%
}

.alert-success, .label-success, .modal-success .modal-body {
    background-color: $color-success!important;
    border-color: $color-success!important;
    color: $color-white !important;
}

.alert-danger, .label-danger, .modal-danger .modal-danger {
    background-color: $color-error !important;
    border-color: $color-error !important;
    color: $color-white !important;
}

.alert-warning, .label-warning, .modal-warning .modal-warning {
    background-color: $color-warning !important;
    border-color: $color-warning !important;
    color: $color-black-text !important;
}

.alert-primary, .label-primary, .modal-primary .modal-primary {
    background-color: $color-primary !important;
    border-color: $color-primary!important;
    color: $color-black-text !important;
}

.alert-secondary, .label-secondary, .label-default {
    background-color: $color-secondary !important;
    color: $color-white !important;
    border-color: $color-secondary!important;
}

//.alert-info, .label-info, .modal-info .modal-info {
//    background-color: $color-primary !important;
//    border-color: $color-primary !important;
//    color: $color-black-text !important;
//}

                    /******             5 -  primary box                  ******/

.box.box-primary {
    border-top-color: $color-primary;
}

.nav-tabs-custom>.nav-tabs>li.active {
    border-top-color: $color-primary;
}

.box-header {
    border-top-color: $color-primary;
}

                    /******              6 - Pagination                     ******/

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: $color-primary!important;
    color: $color-white;
    border: 1px solid $color-grey;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
    color: $color-primary;
}

/*
 title
*/
h6 small {
    color: $color-black-text !important;
}
