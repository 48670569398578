.leaflet-container {
    height: 100%;
    width: 100%;
}

.map-container {
    height: 250px;
    position: relative;
    border-radius: 8px;
}

.map-container-positions {
    height: 636px;
    position: relative;
    border-radius: 8px;
}

.metrics-separator {
    border-right: 1px solid #3c8dbc;
}
